<template>
  <div v-show="isLoading">
    <div class="fullPage d-flex justify-content-center align-items-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      required: true,
      type: Boolean,
    }
  },
}
</script>

<style lang="scss" scoped>
  .fullPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    opacity: 0.9;
    z-index: 1060;
  }
</style>