<script>
import axios from "axios";
import { authHeader } from "../../helpers/auth-header";

export default {
    components: {

    },
    props: {
        fields: {
            required: true,
            type: Array,
        },
        setAdminView: {

        },
        initialSetting: {

        },
        setRecords: {

        },
        path: {
            required: true,
            type: String,
        },
        listName: {
            required: true,
            type: String,
        },
        from: {
            required: false,
            type: String,
        },
        apiDataToGet: {

        }
    },
    data() {
        return {
            initial: false,
            records: [],
            token: '',
            totalRows: 0,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filterOn: [],
            sortBy: "id",
            sortDesc: true,
            loading: false,
            checkedAll: false,
            checks: [],
            keyword: '',
            filter: {
                current_page: 1,
            },
            totalCommission: 0,
            has_permission: true,
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            console.log('zxc');
            return this.totalRows;
        },

        checkedValues() {
            const values = [];
            for (const check of this.checks) {
                if (check.checked) {
                    values.push(check.value);
                }
            }

            return values;
        },
    },
    watch: {
        checkedAll(newValue) {
            console.log(newValue);
            for (const key of Object.keys(this.checks)) {
                this.checks[key].checked = newValue;
            }
        }
    },
    async mounted() {
        if (this.from === 'report') {
            this.filter.status = 'completed';
        }
    },
    methods: {
        async retrieveRecords(ctx) {
            this.loading = true;
            let records = [];

            try {
                const sort = this.sortBy;
                const sortOrder = this.sortDesc ? 'desc' : 'asc';

                const item = await axios.get(`${process.env.VUE_APP_APIURL}/${this.path}`, {
                    headers: authHeader(),
                    params: Object.assign({
                        sort: sort,
                        sort_order: sortOrder,
                        length: ctx.perPage,
                        keyword: this.keyword,
                        filter: this.filter,
                    }, this.initial ? {
                        initial: this.initial,
                    } : {}),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    records = result['data'][this.listName];
                    records = records.map(e => {
                        for (const [value] of Object.entries(e)) {
                            if (e[value] == null) {
                                e[value] = '-';
                            }
                        }
                        return e;
                    });
                    this.totalRows = result['data']['recordsTotal'];
                    
                    if (this.setRecords) {
                        this.setRecords(records);
                    }

                    const checks = [];
                    for (let i = 0; i < records.length; i++) {
                        checks.push({ value: records[i].id || null, checked: false });
                    }
                    this.checks = checks;

                    if (this.initial) {
                        const adminView = result['data']['admin_view'];
                        if (adminView) {
                            if (adminView['sorting'] !== null) {
                                this.sortBy = adminView['sorting'];
                            }
                            if (adminView['size'] !== null) {
                                this.sortDesc = adminView['sorting_by'] === 'desc';
                            }
                            if (adminView['size'] !== null) {
                                this.perPage = adminView['size'];
                            }
                            if (adminView['filter'] !== null && adminView['filter'].length > 0) {
                                const filter = JSON.parse(adminView['filter']);
                                if (this.setAdminView) {
                                    filter['current_page'] = 1;
                                    this.setAdminView(filter);
                                }
                                this.filter = Object.assign(this.filter, filter);
                                // this.filter.current_page = parseInt(filter['current_page']);
                            }

                            if (this.initialSetting) {
                                this.initialSetting();
                            }
                        }

                        this.initial = false;
                    }

                    if (this.apiDataToGet != '' || 1===0) {
                        let getValue = result['data'][this.apiDataToGet] != undefined ? result['data'][this.apiDataToGet] : '';
                        this.$emit('getApiData', getValue);
                    } 
                }
                if (result.code == 'permission_denied') {
                    this.has_permission = false;
                }
            } catch (e) {
                console.error(e);
            }
            
            this.loading = false;
            return records;
        },

        sortChanged() {
            this.filter.current_page = 1;
        },

        filterTable(filter = {}, keyword = '') {
            this.filter = Object.assign(this.filter, filter);
            this.keyword = keyword;
            this.filter.current_page = 1;
            this.$root.$emit('bv::refresh::table', 'my-table')
        },

        clearChecks() {
            this.checks = [];
            this.checkedAll = false;
        }
    }
};
</script>

<template>
    <div>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <b-table hover :items="retrieveRecords" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="filter.current_page" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :no-local-sorting="true" :busy="loading" @sort-changed="sortChanged" id="my-table" show-empty>
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                    </div>
                </template>
                <!-- <template v-slot:head(checkbox)>
                    <input type="checkbox" v-model="checkedAll" value="1" />
                </template> -->
                <!-- <template v-slot:cell(checkbox)="{ item, index }">
                    <input type="checkbox" v-model="checks[index].checked" value="1" />
                </template> -->
                <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                    <slot :name="name" v-bind="data"></slot>
                </template>
                <template #empty="scope">
                    <div class="has-text-centered text-center" v-if="has_permission == true">{{ scope.emptyText }}</div>
                    <div class="has-text-centered text-center" v-else>Permission Denied</div>
                </template>
                <!-- <template #bottom-row v-if="from === 'report'">
                    <b-td :colspan="fields.length - 1" class="text-right">
                        <strong>Total Commission: </strong>
                    </b-td>
                    <b-td class="text-left">
                        <strong>S${{ totalCommission.toFixed(2) }}</strong>
                    </b-td>
                </template> -->
            </b-table>
        </div>
        <div class="row pb-3" v-if="totalRows > 0">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="filter.current_page" :total-rows="totalRows"
                            :per-page="perPage"></b-pagination>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="scss">
.table_entries {
    width: 60px;
    margin: 0 8px;
}

.new-list-tb thead th, .new-list-tb td {
    border-top: 1px solid #CDCDCD80;
    border-bottom: 1px solid #CDCDCD80;
}

.new-list-tb thead th div {
    font-weight: 700;
    font-family: "Nunito";
    color: #2D3748;
}

.new-list-tb td {
    font-weight: 600;
    font-family: "Nunito";
    color: #2D3748;
   
}

.new-list-page-active .page-item.active .page-link {
    background: #FE4727;
}
</style>